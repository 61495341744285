import React from 'react';
import '../css/Footer.css';

const Footer = () => {
	return (
		<footer className='footer'>
			<p>About us.</p>
			<p>ⓒDAITSHoldings</p>
			<p>Corporate registration number : 216-23-76521</p>
			<p>Address. 240, Pangyoyeok-ro, Bundang-gu,</p>
			<p>Seongnam-si, Gyeonggi-do, Republic of Korea</p>
			<br />
			<p>Contact Us.</p>
		</footer>
	);
};

export default Footer;
