import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Nav.css';

const Nav = ({ page }) => {
	return (
		<nav className='nav'>
			<Link className={page === 'filler' ? 'select' : ''} to='/filler'>
				FILLER
			</Link>
			<Link className={page === 'skin' ? 'select' : ''} to='/skin'>
				SKIN BOOSTER
			</Link>
			<Link className={page === 'mts' ? 'select' : ''} to='/mts'>
				MTS ROLLER
			</Link>
			<Link className={page === 'cosmetic' ? 'select' : ''} to='/cosmetic'>
				COSMETIC
			</Link>
			<Link className={page === 'health' ? 'select' : ''} to='/health'>
				HEALTH & DIET PRODUCT
			</Link>
		</nav>
	);
};

export default Nav;
