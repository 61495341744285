import React from 'react';
import Nav from './components/Nav';
import './css/Mts.css';

const Mts = () => {
	return (
		<div className='mts-container'>
			<Nav page='mts' />
			<div className='mts'>
				<h1>MTS ROLLER</h1>
				<div>
					<b>Brand</b>
					<p>
						Air Injection Facial Roller (MTS Roller)
						<br />
						<br />
						Home care MTS roller that minimizes skin irritation and maximizes
						skin absorption of cosmetics.
						<br />
						<br />
						It helps your skin absorb makeup, like you’re getting professional
						beauty care at home
					</p>
				</div>
				<div>
					<b>Packing</b>
					<p>1 Box, 1 Roller</p>
				</div>
				<img src='images/mts1.png' />
				<img src='images/mts2.png' />
			</div>
		</div>
	);
};

export default Mts;
