import React from 'react';
import Nav from './components/Nav';
import './css/Skinbooster.css';

const Skinbooster = () => {
	return (
		<div className='skinbooster-container'>
			<Nav page='skin' />
			<div className='skinbooster'>
				<h1>SKIN BOOSTER</h1>
				<div className='skinbooster-info'>
					<h2>Vital Exo Complex Ampoule</h2>
					<h3>
						The product regenerates skin fast by promoting wound healing and
						collagenesis with stem cell exosome active ingerdients.
					</h3>
					<div>
						<img src='images/skinbooster1.jpg' />
						<div>
							<b>Brand</b>
							<p>Vital Exo Complex Ampoule</p>
							<b>Whole materials</b>
							<p>Purified Water</p>
							<p>Human Adipose Derived Stem Cell</p>
							<p>Conditioned Media Human Adipose Stromal</p>
							<p>Cell Conditioned Media</p>
							<p> Sodium Chloride Sodium Bicarbonate</p>
							<b>Packing</b>
							<p>3ml / 0.101floz * 4 VIALS</p>
						</div>
					</div>
				</div>
				<div className='skinbooster-info'>
					<h2>Pure hydro Ampoule</h2>
					<h3>
						The product enhances the moisture and moisture of the skin, and
						regeneration of the skin.
					</h3>
					<div>
						<img src='images/skinbooster2.jpg' />
						<div>
							<b>Brand</b>
							<p>Pure Hydro Ampoule</p>
							<b>Whole materials</b>
							<p>Purified Water</p>
							<p>Sodium Hyaluronate</p>
							<p>Niacinamide</p>
							<p>Sodium Chloride</p>
							<p>Sodium Bicarbonate</p>
							<b>Packing</b>
							<p>5ml / 0.169floz * 4 VIALS</p>
						</div>
					</div>
				</div>
				<div className='skinbooster-info'>
					<h2>Shiny Bright Ampoule (white jade injection)</h2>
					<h3>
						Strong Moisture improve wrinkles / Skin regeneration / Skin glow /
						Improve skin elasticity
					</h3>
					<div>
						<img src='images/skinbooster3.jpg' />
						<div>
							<b>Brand</b>
							<p>Shiny bright ampoule</p>
							<b>Whole materials</b>
							<p>Purified Water </p>
							<p>Peptide complex</p>
							<p>Glutathione</p>
							<p>Sodium chloride</p>
							<p> Sodium bicarbonate</p>
							<b>Packing</b>
							<p>4ml / 0.135floz * 4 VIALS</p>
						</div>
					</div>
				</div>
				<div className='skinbooster-info'>
					<h2>Vita Energy Slim Ampoule</h2>
					<h3>
						The product gives energy to dry, thirsty skin, Ampoule that gives
						moisture and glow.
					</h3>
					<div>
						<img src='images/skinbooster4.jpg' />
						<div>
							<b>Brand</b>
							<p>Vita Energy Slim Ampoule</p>
							<b>Whole materials</b>
							<p>Purified Water</p>
							<p>Cyanocobalamine</p>
							<p>Arginine</p>
							<p>Sodium Chloride</p>
							<p>Sodium bicarbonate</p>
							<b>Packing</b>
							<p>10ml / 0.338floz * 4 VIALS</p>
						</div>
					</div>
				</div>
				<div className='skinbooster-info'>
					<h2>Vital Exo Complex Ampoule</h2>
					<h3>
						Tension on uneven lines, Ampoule exclusively for V Zone for smooth
						and elastic skin
					</h3>
					<div>
						<img src='images/skinbooster5.jpg' />
						<div>
							<b>Brand</b>
							<p>Pure Slim Ampoule</p>
							<b>Whole materials</b>
							<p>Purified Water </p>
							<p>Carnitine</p>
							<p>Arginine</p>
							<p>Sodium Chloride</p>
							<p>Sodium Bicarbonate</p>
							<b>Packing</b>
							<p>10ml / 0.338floz * 4 VIALS</p>
						</div>
					</div>
				</div>
				<div className='skinbooster-info'>
					<h2>Vital Exo Complex Ampoule</h2>
					<h3>
						Salmon semen extract for pore reduction, wrinkle improvement and
						skin regeneration
					</h3>
					<div>
						<img src='images/skinbooster6.jpg' />
						<div>
							<b>Brand</b>
							<p>Nucleotide Power Skin Ampoule</p>
							<b>Whole materials</b>
							<p>Purified Water</p>
							<p>Sodium DNA</p>
							<p>Glycerin Media</p>
							<p>Sodium Chloride</p>
							<p>Sodium Bicarbo</p>
							<b>Packing</b>
							<p>4ml / 0.135floz * 4 VIALS</p>
						</div>
					</div>
				</div>
				<div className='skinbooster-info'>
					<h2>Vital Exo Complex Ampoule</h2>
					<h3>
						“Natural source of skin nutrition” with regenerative effects in case
						of skin damage”
						<br />
						Premium ampoule with various growth factors useful for skin
					</h3>

					<div>
						<img src='images/skinbooster7.jpg' />
						<div>
							<b>Brand</b>
							<p>Nutrient stem cell ampoule</p>
							<b>Whole materials</b>
							<p>Purified Water</p>
							<p>Human Adipose Stromal Cell Conditioned Media</p>
							<p>Sodium Hyaluronate</p>
							<p>Sodium Chloride</p>
							<b>Packing</b>
							<p>3ml / 0.101floz * 4 VIALS</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Skinbooster;
