import React from 'react';
import './css/About.css';

const About = () => {
	return (
		<div className='about'>
			<h1 className='about-title'>About</h1>
			<div className='mainCover'>
				<h1>
					DaitsHoldings
					<br />
					Business
				</h1>
			</div>

			<div className='business'>
				<h3>
					DAITSHoldings
					<br />
					Business field
				</h3>
				<div>
					<p>
						We’ve, DaitsHoldings, been underway modeling and incubating several
						globalized businesses based on cutting edge technologies.
					</p>

					<p>
						DAITSHoldings Incubates Fintech Platform and New Material Deposition
						Business and is conducting trade business based in the Middle East,
						Central Asia, and Southeast Asia.
					</p>
					<p>
						Build a global business chain through each incubating project,
						expand its business to establish overseas branches and factories.
					</p>
					<p>
						The incubating project promoted by DAITSHoldings will be
						commercialized and spun off at the same time..
					</p>
				</div>
			</div>

			<div className='bic'>
				<h1>BIC platform</h1>
			</div>

			<div className='blockchain'>
				<div>
					<h3>
						New solution
						<br />
						for capital raising
						<br />
						and asset management
					</h3>
					<p>
						Smooth investment attraction from SMEs Secure liquidity based on
						company CBs and stocks Operate safe collection and loss compensation
						program to protect investors’ assets
					</p>
				</div>
				<div>
					<h3>
						Decentralized
						<br />
						Finance Platform
					</h3>
					<p>
						The platform is operated as a DEX method without platform
						intervention other than the verification by a preliminary panel to
						evaluate the investment value of a company, and supports investors’
						various investment activities
					</p>
				</div>
				<img src='/images/about_2.png' />
				<div>
					<h3>Global Investment</h3>
					<p>
						Suggests new alternatives to secure liquidity of SMEs at home and
						abroad
						<br />
						<br />
						Establishes Global Network of domestic and international companies
						and discovers highly profitable project
					</p>
				</div>
				<div>
					<h3>
						Safe investment certificate
						<br />
						based on real asset
					</h3>
					<p>
						Provides safe investment certificate and an asset management
						solution based on real asset such as real estate / energy (carbon
						credit) /artwork / digital asset.
					</p>
				</div>
			</div>

			<div className='bronze'>
				<h4>Bronze 502</h4>
			</div>

			<div className='bronze502'>
				<h4>Bronze 502</h4>
				<div>
					<p>
						The 'Bronze 502' bases on the development of new material, descend
						to advantage of Korean traditional bronze by Air vacuum hybrid
						plasma coat.
					</p>
					<p>
						Air vacuum hybrid plasma coat is vapor deposited by plasma stated
						stainless steel which ionized metal (Ti, Zr, Cu, Sn, Ag or CuSn
						alloy) that follow stages homogeneity also wear well.
					</p>
				</div>
			</div>

			<div className='global'>
				<h3>
					Global brand building
					<br />
					through international trading
				</h3>
			</div>

			<section className='global-trading'>
				<div>
					<h3>
						Global
						<br />
						brand building
					</h3>
					<p>Medical Device, Healthcare and cosmetic</p>
				</div>
				<section className='icon-section'>
					<div>
						<img className='icon' src='/images/about-icon1.png' />
						<p>
							Export to Middle East, Europe, and Southeast Asia through
							Iranian/Dubai/Vietnam branches
						</p>
					</div>
					<div>
						<img className='icon' src='/images/about-icon2.png' />
						<p>
							Launching a global brand by gaining brand awareness through
							exports to companies
						</p>
					</div>
					<div>
						<img className='icon' src='/images/about-icon3.png' />
						<p>Web/SNS-based Overseas B2B Mall Establishment</p>
					</div>
				</section>
			</section>

			<div className='export_import'>
				<img className='about-img-text' src='/images/about4.png' />
				<img className='about-img' src='/images/about1.png' />
				<img className='about-img' src='/images/about3.png' />
				<img className='about-img-text' src='/images/about2.png' />
			</div>
		</div>
	);
};

export default About;
