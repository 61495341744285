import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './css/Home.css';

const Home = () => {
	const [openLocation, setOpenLocation] = useState(false);

	return (
		<main>
			<img className='mainImg' alt='mainImg' src='/images/home_main.jpeg' />

			<section className='info'>
				<p>
					DAITSHoldings establishes a global distribution network through the
					foundation of overseas branches.
				</p>
				<p>
					All products DAITSHoldings exports retain exclusive rights,
					guaranteeing stable supply.
				</p>
			</section>

			<div className='location'>
				<img
					alt='worldMap'
					onClick={() => setOpenLocation(!openLocation)}
					className='location-map'
					src='/images/location_map.png'
				/>
				<img
					alt='worldMap'
					className='location-map-mobile'
					src='/images/location_map_mobile_change.png'
				/>
				<section className={`locationInfo ${openLocation ? 'open' : 'close'}`}>
					<div className='locationInfo-box'>
						<h2>VIETNAM BRANCH</h2>
						<p>
							137 st. Chinh Kinh, Nhan Chinh, District Thanh Xuan, Hanoi City,
							Vietnam
						</p>
					</div>
					<div className='locationInfo-box'>
						<h2>IRAN BRANCH</h2>
						<p>
							18, Floor 5, No. 7, Shahid Masoud Khani street, Shahid Jalil Seyed
							Ali Street, Tehranpars, Tehran, Iran postal code : 1655647583
						</p>
					</div>
					<div className='locationInfo-box'>
						<h2>DUBAI BRANCH</h2>
						<p>
							HD 165, 18th floor, Sheikh Rashid Tower, Shaikh Zayed Road, Dubai
							World Trade Center 9292 Dubai UNITED ARAB EMIRATES
						</p>
					</div>
				</section>
			</div>

			<h2 className='title'>
				<b>Global brand building</b>
				<br />
				Medical Device, Healthcare and cosmetic
			</h2>

			<section className='section'>
				<img
					className='section-img'
					alt='filterImg'
					src='/images/home_filter.png'
				/>
				<div className='section-p'>
					<h1>FILLER</h1>
					<h2>Brand – DERMARCANE Filler</h2>
					<p>
						We guarantee product quality more excellent than others through
						Dermarcane’s own special curing process.
					</p>
					<p>
						This is a safe product that went through a special dialysis process
						to eliminate 100% impurities in filler ingredients.
					</p>
					<button className='more-button'>
						<Link to='filler'>MORE</Link>
					</button>
				</div>
			</section>
			<section className='section'>
				<img
					className='section-img'
					alt='skinBooster'
					src='/images/home_skinbooster_02.png'
				/>
				<div className='section-p'>
					<h1>SKIN BOOSTER</h1>
					<h2>Brand - EEN Skinbooster</h2>
					<p>
						Skin booster regenerates the skin, restores elasticity and is used
						for skin soothing effect.
					</p>
					<p>
						We introduce an exclusive product for skin soothing and regeneration
						after filler injection.
					</p>
					<button className='more-button'>
						<Link to='/skin'>MORE</Link>
					</button>
				</div>
			</section>

			<section className='section'>
				<div className='section-p'>
					<h1>MTS ROLLER</h1>
					<h2>Brand – Air Injection Facial Roller</h2>
					<p>
						This product enhances the absorption of cosmetics by penetrating
						them deeply into the skin.
					</p>
					<p>
						Use MTS roller when you use skin booster and expensive cosmetics.
					</p>
					<button className='more-button'>
						<Link to='/mts'>MORE</Link>
					</button>
				</div>
				<img className='section-img' src='/images/home_mtsroller.png' />
			</section>
			<section className='section'>
				<img className='section-img' src='/images/home_cosmetic.png' />
				<div className='section-p' style={{ paddingTop: 0, paddingBottom: 0 }}>
					<h1>COSMETIC</h1>
					<h2>Brand – CellbyCell Cosmetic</h2>
					<p>
						The only method to protect the skin from being cracked and losing
						elasticity in dry air and under hot UV.
					</p>
					<p>
						High moisturizing and high nutrient cosmetics 1,300 Korean
						dermatologies selected.
					</p>
					<button className='more-button'>
						<Link to='cosmetic'>MORE</Link>
					</button>
				</div>
			</section>
			<section className='section'>
				<img className='section-img' src='/images/home_healthfood.png' />

				<div className='section-p' style={{ paddingTop: 0, paddingBottom: 0 }}>
					<h1>HEALTH FOOD</h1>
					<h2>Brand – NextX health food</h2>
					<p>
						Select the product you want and develop the habit of eating one
						stick every day.
					</p>
					<p>
						This provides an all-in-one solution from health to beauty and diet.
					</p>
					<button className='more-button'>
						<Link to='health'>MORE</Link>
					</button>
				</div>
			</section>
		</main>
	);
};

export default Home;
