import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './css/MobileNav.css';

const MobileNav = ({ isOpen, openHandler }) => {
	const [isOpenProducts, setIsOpenProducts] = useState(false);
	return (
		<div className={isOpen ? 'mobileNav open' : 'mobileNav close'}>
			<button
				onClick={openHandler}
				style={{
					padding: 0,
					background: 'none',
					border: 'none',
					position: 'absolute',
					top: 25,
					right: 20,
				}}
			>
				<img
					src='icon/close.png'
					style={{ width: 20, height: 20 }}
					alt='close'
				/>
			</button>
			<Link to='/' onClick={openHandler}>
				Home
			</Link>
			<Link to='/about' onClick={openHandler}>
				About
			</Link>
			<button onClick={() => setIsOpenProducts(!isOpenProducts)}>
				Global brand building
				<img
					className={`arrow ${isOpenProducts ? 'open' : ''}`}
					src='icon/arrow.png'
					style={{ width: 12 }}
					alt='arrow'
				/>
			</button>

			{isOpenProducts && (
				<div className={`products ${isOpenProducts ? 'open' : ''}`}>
					<Link to='filler' onClick={openHandler}>
						Filler
					</Link>
					<Link to='skin' onClick={openHandler}>
						Skinbooster
					</Link>
					<Link to='mts' onClick={openHandler}>
						MTS Roller
					</Link>
					<Link to='cosmetic' onClick={openHandler}>
						Cosmetic
					</Link>
					<Link to='health' onClick={openHandler}>
						Health& Diet Product
					</Link>
				</div>
			)}

			<Link to='contact' onClick={openHandler}>
				Contact
			</Link>
		</div>
	);
};

export default MobileNav;
