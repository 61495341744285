import React from 'react';
import Nav from './components/Nav';
import './css/Health.css';

const Health = () => {
	return (
		<div className='health-container'>
			<Nav page='health' />
			<div className='health'>
				<h1>HEALTH & DIET PRODUCT</h1>
				<div>
					<h2>MY BODY CARE ENZYME LACTOBACILUS</h2>
					<div>
						<img src='images/health1.png' />
						<div>
							<b>Product Name</b>
							<p>My body care Enzyme Lactobacilus</p>
							<b>Effect</b>
							<p>An enzyme made from 15 kinds of grains</p>
							<b>Capacity</b>
							<p>3g * 60sticks</p>
							<b>HS code</b>
							<p>3507.90-9000</p>
						</div>
					</div>
				</div>
				<div>
					<h2>GORYEO GINSENG GOLD</h2>
					<div>
						<img src='images/health2.png' />
						<div>
							<b>Product Name</b>
							<p>Goryeo ginseng gold</p>
							<b>Effect</b>
							<p>
								It is convenient to use 6-year-old red ginseng that contains the
								most ginseng glycoside ginoside (saponin) ingredients
							</p>
							<b>Capacity</b>
							<p>10g*32bags</p>
							<b>HS code</b>
							<p>1302.19-1210</p>
						</div>
					</div>
				</div>
				<div>
					<h2>PRE – PRO POST BIOTICS</h2>
					<div>
						<img src='images/health3.png' />
						<div>
							<b>Product Name</b>
							<p>Pre – pro Post biotics</p>
							<b>Effect</b>
							<p>
								4th Generation Lactobacillus
								<br />
								Kimchi-derived
								<br />
								Breast Milk-derived
								<br />
								Postbiotics including patented Lactobacillus
								<br />
								are Providers
							</p>
							<b>Capacity</b>
							<p>3g * 60sticks</p>
							<b>HS code</b>
							<p>2106.90-9099</p>
						</div>
					</div>
				</div>
				<div>
					<h2>19 GRAIN ENZYMES</h2>
					<div>
						<img src='images/health4.png' />
						<div>
							<b>Product Name</b>
							<p>19 Grain Enzymes</p>
							<b>Effect</b>
							<p>
								17 types of mixed lactobacillus & 5 types of digestive enzymes
								<br />8 types of vitamins & 4 types of minerals
								<br />
								20 types of vegetable fruit powder included
							</p>
							<b>Capacity</b>
							<p>10g*32bags</p>
							<b>HS code</b>
							<p>1302.19-1210</p>
						</div>
					</div>
				</div>
				<div>
					<h2>SHINELOG VERY GLOW COLLAGEN</h2>
					<div>
						<img src='images/health5.png' />
						<div>
							<b>Product Name</b>
							<p>Shinelog VERY GLOW COLLAGEN</p>
							<b>Effect</b>
							<p>
								Low molecular fish collagen peptide 1,000mg Low molecular fish
								collagen with good absorption Choice for elastic skin Best
								choice for your skin
							</p>
							<b>Capacity</b>
							<p>3g * 30sticks</p>
							<b>HS code</b>
							<p>2106.90-9099</p>
						</div>
					</div>
				</div>
				<div>
					<h2>COOL MINT PEAR AND BELLFLOWER ROOT EXTRACT STICK</h2>
					<div>
						<img src='images/health6.png' />
						<div>
							<b>Product Name</b>
							<p>Cool mint Pear and Bellflower root Extract stick</p>
							<b>Effect</b>
							<p>
								The refreshing feeling that lasts for a minute. Ingredients 100%
								Korean
							</p>
							<b>Capacity</b>
							<p>10g * 30sticks</p>
							<b>HS code</b>
							<p>2202.99-900</p>
						</div>
					</div>
				</div>
				<div>
					<h2>SURITAEPYEONG WITH RED GINSENG</h2>
					<div>
						<img src='images/health7.png' />
						<div>
							<b>Product Name</b>
							<p>SuRitaepyeong with red ginseng</p>
							<b>Effect</b>
							<p>
								The best health food made with traditional Korean herbal
								ingredients and red ginseng. a healthy morning with 1sticka day
							</p>
							<b>Capacity</b>
							<p>10ml * 30sticks</p>
							<b>HS code</b>
							<p>2202.99-9000</p>
						</div>
					</div>
				</div>
				<div>
					<h2>MOSTANS 1865(BLACK SOYBEAN PILL)</h2>
					<div>
						<img src='images/health8.png' />
						<div>
							<b>Product Name</b>
							<p>Mostans 1865(black soybean pill)</p>
							<b>Effect</b>
							<p>
								5 Black Foods in one stick It’s good for your hair Hair loss
								control food
							</p>
							<b>Capacity</b>
							<p>3g * 60sticks</p>
							<b>HS code</b>
							<p>3507.90-9000</p>
						</div>
					</div>
				</div>

				<div>
					<h2>BENEROOT GOAT COLOSTRUM PROTEIN WITH RED-GINSENG</h2>
					<div>
						<img src='images/health9.png' />
						<div>
							<b>Product Name</b>
							<p>Beneroot Goat colostrum protein with red-ginseng</p>
							<b>Effect</b>
							<p>
								Helps with joint and bone health Activating Digestive Power
								diabetes prevention effect
							</p>
							<b>Capacity</b>
							<p>5g * 30sticks</p>
							<b>HS code</b>
							<p>2106.10-9020</p>
						</div>
					</div>
				</div>
				<div>
					<h2>DIET COFFEE, ARABICA GREENBEAN COFFEE</h2>
					<div>
						<img src='images/health10.png' />
						<div>
							<b>Product Name</b>
							<p>Diet coffee, Arabica Greenbean coffee</p>
							<b>Effect</b>
							<p>
								Individual recognized body fat reduction functional raw
								materials Verification of test results for human body
								application of green coffee bean liquor extract of functional
								raw material Diet with hazelnut-flavored coffee!!
							</p>
							<b>Capacity</b>
							<p>2g * 30sticks</p>
							<b>HS code</b>
							<p>2106.90-9099</p>
						</div>
					</div>
				</div>
				<div>
					<h2>SALEDIT</h2>
					<div>
						<img src='images/health11.png' />
						<div>
							<b>Product Name</b>
							<p>Saledit</p>
							<b>Effect</b>
							<p>
								20 kinds of vegetables at once!! Salad that I need for my body!!
								Eat 20 vegetables and 3 lactic acid bacteria with 2 pills a day
							</p>
							<b>Capacity</b>
							<p>500mg * 60tablet * 3bottles</p>
							<b>HS code</b>
							<p>2102.20-4010</p>
						</div>
					</div>
				</div>
				<div>
					<h2>BURNING GRAM</h2>
					<div>
						<img src='images/health12.png' />
						<div>
							<b>Product Name</b>
							<p>Burning gram</p>
							<b>Effect</b>
							<p>
								1 stick for a day, for light diet It’s powdery, so you can
								easily put it in water and eat it anytime, anywhere
							</p>
							<b>Capacity</b>
							<p>3g*30sticks</p>
							<b>HS code</b>
							<p>2106.90-9099</p>
						</div>
					</div>
				</div>
				<div>
					<h2>Cheongdam Bichaek with red ginseng and wild ginseng</h2>
					<div>
						<img src='images/health13.png' />
						<div>
							<b>Product Name</b>
							<p>Cheongdam Bichaek with red ginseng and wild ginseng</p>
							<b>Effect</b>
							<p>
								120 Years of Same Cultivation of Wild Ginseng Energy recovery
								synergy Use of precious and expensive materials
							</p>
							<b>Capacity</b>
							<p>10ml * 8sticks * 4case</p>
							<b>HS code</b>
							<p>2106.90-3029</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Health;
