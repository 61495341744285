import { Routes, Route, Redirect } from 'react-router-dom';
import Home from './Home';
import Header from './components/Header';
import Footer from './components/Footer';
import Products from './Products';
import MobileNav from './MobileNav';
import { useState } from 'react';
import Filler from './Filler';
import './css/reset.css';
import './App.css';
import Skinbooster from './Skinbooster';
import Mts from './Mts';
import Health from './Health';
import Cosmetic from './Cosmetic';
import About from './About';
import Contact from './Contact';

function App() {
	const [isOpen, setIsOpen] = useState(false);
	const openHandler = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div style={{ background: 'white' }}>
			<MobileNav isOpen={isOpen} openHandler={openHandler} />
			<Header openHandler={openHandler} />
			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/about' element={<About />} />
				<Route path='/products' element={<Products />} />
				<Route path='/filler' element={<Filler />} />
				<Route path='/skin' element={<Skinbooster />} />
				<Route path='/mts' element={<Mts />} />
				<Route path='/health' element={<Health />} />
				<Route path='/cosmetic' element={<Cosmetic />} />
				<Route path='/contact' element={<Contact />} />
			</Routes>
			<Footer />
		</div>
	);
}

export default App;
