import React from 'react';
import Nav from './components/Nav';
import './css/Cosmetic.css';

const Cosmetic = () => {
	return (
		<div className='cosmetic-container'>
			<Nav page='cosmetic' />
			<div className='cosmetic'>
				<h1>COSMETIC</h1>
				<p>Skin solutions from 1,300 dermatologists in Korea</p>
				<div>
					<h2>Intensive Serum BB</h2>
					<div>
						<img src='images/cosmetic1.png' />
						<div>
							<h3>
								Daily makeup
								<br />
								UV protection <br />
								Wrinkle improvement
							</h3>
							<b>Product Name</b>
							<p>Intensive Serum BB</p>
							<b>Effect</b>
							<p>BB that maintains moisture with serum ingredients.</p>
							<b>Capacity</b>
							<p>50ml</p>
						</div>
					</div>
				</div>
				<div>
					<h2>Hydra C Moisture Cream</h2>
					<div>
						<img src='images/cosmetic2.png' />
						<div>
							<h3>
								Strengthen moisture retention
								<br />
								Improve moisture barrier
							</h3>
							<b>Product Name</b>
							<p>Hydra C Moisture Cream</p>
							<b>Effect</b>
							<p>Contains ceramide and hyaluron high moisture cream</p>
							<b>Capacity</b>
							<p>100ml / 200ml</p>
						</div>
					</div>
				</div>
				<div>
					<h2>AZULENE Complex Serum</h2>
					<div>
						<img src='images/cosmetic3.png' />
						<div>
							<b>Product Name</b>
							<p>AZULENE Complex Serum</p>
							<b>Effect</b>
							<p>Strengthen skin soothing and moisture barrier</p>
							<b>Capacity</b>
							<p>50ml</p>
						</div>
					</div>
				</div>
				<div>
					<h2>Barrier C Rejuvenation Cream</h2>
					<div>
						<img src='images/cosmetic4.png' />
						<div>
							<h3>
								Complex Peptides
								<br />
								Skin Recovery
							</h3>
							<b>Product Name</b>
							<p>Barrier C Rejuvenation Cream</p>
							<b>Effect</b>
							<p>Skin regeneration, high nutrition cream</p>
							<b>Capacity</b>
							<p>50ml</p>
						</div>
					</div>
				</div>
				<div>
					<h2>Intensive Serum BB</h2>
					<div>
						<img src='images/cosmetic5.png' />
						<div>
							<h3>
								Daily makeup
								<br />
								UV protection
								<br />
								Wrinkle improvement
							</h3>
							<b>Product Name</b>
							<p>Intensive Serum BB</p>
							<b>Effect</b>
							<p>BB that maintains moisture with serum ingredients.</p>
							<b>Capacity</b>
							<p>50ml</p>
						</div>
					</div>
				</div>
				<div>
					<h2>REJUVER Complex Serum</h2>
					<div>
						<img src='images/cosmetic6.png' />
						<div>
							<b>Product Name</b>
							<p>REJUVER Complex Serum</p>
							<b>Effect</b>
							<p>Skin barrier, nutritional serum</p>
							<b>Capacity</b>
							<p>50ml</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Cosmetic;
