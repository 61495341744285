import React, { useState } from 'react';
import './css/Contact.css';
import emailjs from '@emailjs/browser';

const Contact = () => {
	const [form, setForm] = useState({
		name: '',
		email: '',
		contact: '',
		location: '',
		message: '',
	});
	const onChangeText = (type) => (e) => {
		setForm({ ...form, [type]: e.target.value });
	};

	const send = () => {
		emailjs
			.send('service_vdmmrpq', 'template_33t6sac', form, 'BPbZeWlC8Oxeyrue6')
			.then(
				function (response) {
					alert('메세지가 전송되었습니다.');
					setForm({
						name: '',
						email: '',
						contact: '',
						location: '',
						message: '',
					});
				},
				function (error) {
					// console.log('FAILED...', error);
				}
			);
	};

	return (
		<div className='contact'>
			<h1>Contact</h1>
			<form>
				<div>
					<label>Name</label>
					<input
						type={'text'}
						onChange={onChangeText('name')}
						value={form.name}
					/>
				</div>
				<div>
					<label>E-mail</label>
					<input
						type={'text'}
						onChange={onChangeText('email')}
						value={form.email}
					/>
				</div>
				<div>
					<label>Contact</label>
					<input
						type={'text'}
						onChange={onChangeText('contact')}
						value={form.contact}
					/>
				</div>
				<div>
					<label>Location</label>
					<input
						type={'text'}
						onChange={onChangeText('location')}
						value={form.location}
					/>
				</div>
				<div>
					<label>Message</label>
					<textarea
						type={'text'}
						onChange={onChangeText('message')}
						value={form.message}
					/>
				</div>
				<button
					onClick={(e) => {
						e.preventDefault();
						send();
					}}
				>
					SEND
				</button>
			</form>
		</div>
	);
};

export default Contact;
