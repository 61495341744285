import React from 'react';
import Nav from './components/Nav';
import './css/Filler.css';

const Filler = () => {
	return (
		<div className='filler-container'>
			<Nav page='filler' />
			<div className='filler'>
				<h1>FILLER</h1>
				<div>
					<h2>Brand</h2>
					<p>DERMARCANE Filler (with lidocaine)</p>
				</div>
				<div>
					<h2>Type</h2>
					<p>
						4Type
						<br />
						White, Red, Green, Grey
						<br />
						Categorized by the area of use and strength of the face
					</p>
				</div>
				<div style={{ marginBottom: 58 }}>
					<h2>Packing</h2>
					<p>
						1 Box, Injectable gel 1.1ml syringe & 2 needles Cross linked
						hyaluronic acid 24mg/ml, lidocaine hydrochloride 3mg/ml
					</p>
				</div>
				<section className='filler-info'>
					<h2>Dermarcane Filler White</h2>
					<div>
						<img src='images/white.png' />
						<div>
							<b>Indication</b>
							<p>Medium facial wrinkles</p>
							<b>Depth of Injection</b>
							<p>Mid-dermis</p>
							<b>HA concentration</b>
							<p>24 mg/ml</p>
							<b>Lidocaine concentration</b>
							<p>3 mg/ml</p>
							<b>Fill volume</b>
							<p>1.1 ml</p>
							<b>Needles</b>
							<p>2 x 27G1/2</p>
							<b>Self life</b>
							<p>24 months</p>
						</div>
					</div>
				</section>
				<section className='filler-info'>
					<h2>Dermarcane Filler Red</h2>
					<div>
						<img src='images/red.png' />
						<div>
							<b>Indication</b>
							<p>Deep facial wrinkles and folds</p>
							<b>Depth of Injection</b>
							<p>Deep-dermis</p>
							<b>HA concentration</b>
							<p>24 mg/ml</p>
							<b>Lidocaine concentration</b>
							<p>3 mg/ml</p>
							<b>Fill volume</b>
							<p>1.1 ml</p>
							<b>Needles</b>
							<p>2 x 26G1/2</p>
							<b>Self life</b>
							<p>24 months</p>
						</div>
					</div>
				</section>
				<section className='filler-info'>
					<h2>Dermarcane Filler Green</h2>
					<div>
						<img src='images/green.png' />
						<div>
							<b>Indication</b>
							<p>Superficial facial wrinkles</p>
							<b>Depth of Injection</b>
							<p>Superficial dermis</p>
							<b>HA concentration</b>
							<p>24 mg/ml</p>
							<b>Lidocaine concentration</b>
							<p>3 mg/ml</p>
							<b>Fill volume</b>
							<p>1.1 ml</p>
							<b>Needles</b>
							<p>2 x 30G1/2</p>
							<b>Self life</b>
							<p>24 months</p>
						</div>
					</div>
				</section>
				<section className='filler-info'>
					<h2>Dermarcane Filler Grey</h2>
					<div>
						<img src='images/grey.png' />
						<div>
							<b>Indication</b>
							<p>Volume creations and facial contours</p>
							<b>Depth of Injection</b>
							<p>Subcutaneous</p>
							<b>HA concentration</b>
							<p>24 mg/ml</p>
							<b>Lidocaine concentration</b>
							<p>3 mg/ml</p>
							<b>Fill volume</b>
							<p>1.1 ml</p>
							<b>Needles</b>
							<p>2 x 25G1/2</p>
							<b>Self life</b>
							<p>24 months</p>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
};

export default Filler;
