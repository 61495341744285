import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/Header.css';

const Header = ({ openHandler }) => {
	const [innerWidth, setInnerWidth] = useState(window.innerWidth);
	const isMobile = innerWidth < 768;

	useEffect(() => {
		const resizeListener = () => {
			setInnerWidth(window.innerWidth);
		};
		window.addEventListener('resize', resizeListener);
	});
	return (
		<header className='header'>
			<div>
				<Link to='/'>
					<img className='logo' src='/images/logo.png' alt='logo' />
				</Link>
				{!isMobile && (
					<>
						<Link to='/about'>About</Link>
						<Link to='/filler'>All products</Link>
					</>
				)}
			</div>

			{!isMobile ? (
				<Link to='contact'>Contact</Link>
			) : (
				<button onClick={openHandler}>
					<img src='/icon/menu.png' />
				</button>
			)}
		</header>
	);
};

export default Header;
